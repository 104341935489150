angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('public.passwordRecoverToken', {
            url: '/passwordRecoverToken/:token',
            views: {
                "public": {
                    controller: 'passwordRecoverTokenCtrl',
                    templateUrl: 'web/public/passwordRecoverToken/passwordRecoverToken.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Password Recover'
            }
        });
    })
    .controller('passwordRecoverTokenCtrl', function ($rootScope, $scope, $state, loginService, $stateParams, $translate) {
        var that = this;
        this.loading = false;
        $scope.domain = $rootScope.dominio;
        this.enviar = function () {
            if (that.password == that.password2) {

                loginService.recoverPasswordToken(md5(that.password), $stateParams.token).then(function () {
                    that.loading = true;
                    swal($translate.instant("¡Correcto!"), $translate.instant("La contraseña se ha cambiado correctamente."), "success");
                    setTimeout(function () {
                        $state.go('public.login');
                    }, 10000);
                }, function () {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant("Hay un error con esta solicitud, por favor, dirígete al login e intenta restablecer la contraseña de nuevo."), "error");
                    setTimeout(function () {
                        $state.go('public.login');
                    }, 10000);
                });
            } else {
                swal($translate.instant('Algo ha ido mal'), $translate.instant("Las contraseñas no coinciden"), "error");
            }
        };
    });
